<template>
	<div class="sell">
		<div class="top">
			<my-nft-list-nav :list="nav_list" :index="nav_index" @click="navTap"/>
		</div>
		<div class="main">
			<div class="left">
				<div class="card-small">
					<img src="@/assets/bg_revoke.svg" alt="">
					<img class="card-img" :src="card_img" alt="">
					<div class="card-content">
						<div class="card-name">{{ card.data.zhHans }}</div>
						<div class="card-info">
							<div class="card-info_item">
								<div class="card-info_left">
									<span></span>
									<span>{{ $t('OWN') }}</span>
								</div>
								<div class="num">{{ card.own }}</div>
							</div>

							<div class="card-info_item">
								<div class="card-info_left">
									<span></span>
									<span>{{ $t('STAKE') }}</span>
								</div>
								<div class="num">{{ card.is_synthetic_card ? card.stake_for_farm : card.stake_for_mine }}</div>
							</div>

<!--							<div class="card-info_item">
								<div class="card-info_left">
									<span></span>
									<span>LOCK</span>
								</div>
								<div class="num">0</div>
							</div>-->

							<div class="card-info_item" v-if="!card.is_synthetic_card">
								<div class="card-info_left">
									<span></span>
									<span>{{ $t('MINING POWER') }}</span>
								</div>
								<div class="num">{{ card.data.mining_power }}</div>
							</div>

							<div class="card-info_item" v-if="card.is_synthetic_card">
								<div class="card-info_left">
									<span></span>
									<span>{{ $t('GOD STRENGTH') }}</span>
								</div>
								<div class="num">{{ card.data.god_strength }}</div>
							</div>

							<div class="card-info_item" v-if="card.is_synthetic_card">
								<div class="card-info_left">
									<span></span>
									<span>{{ $t('BONUS PROPORTION') }}</span>
								</div>
								<div class="num">{{ card.data.bonus_proportion }}%</div>
							</div>
						</div>
					</div>
				</div>

				<div class="form-item">
					<div class="form-label">{{ $t('CHOOSE THE CURRENCY') }}</div>
					<div class="form-input_box">
						<img class="form-logo" src="@/assets/logo.png" alt="">
						<input class="form-input" type="text" value="AMT" disabled>
					</div>
				</div>

				<div class="form-item">
					<div class="form-label">{{ $t('BUY OUT PRICE') }}</div>
					<div class="form-input_box">
						<img class="form-logo" src="@/assets/logo.png" alt="">
						<input class="form-input" type="number" placeholder="0" v-model="price">
					</div>
				</div>

				<div class="form-item">
					<div class="form-label">{{ $t('FEE') }}</div>
					<div class="form-input_box">
						<img class="form-logo" src="@/assets/logo.png" alt="">
						<input class="form-input" type="text" value="5%" disabled>
					</div>
				</div>

				<div class="form-item">
					<div class="form-label">{{ $t('NUM OF NFT') }}</div>
					<div class="form-input_box">
						<img class="form-logo" src="@/assets/logo.png" alt="">
						<input class="form-input" type="number" value="1" disabled>
					</div>
				</div>

				<div class="form-balance">
					<div class="form-balance_label">{{ $t('YOUR BALANCE') }}</div>
					<div class="form-balance_content">
						<img class="form-logo" src="@/assets/logo.png" alt="">
						<span>{{ Number(amt_balance).toFixed(4) }}</span>
					</div>
				</div>

				<div :class="{'text-center': is_mobile}">
					<v-btn
							:style="[is_mobile ? mobile_btn_styles : pc_btn_styles]"
							color="#EB6930"
							large
							outlined
							rounded
							:loading="sell_loading"
							@click="sellCard"
					>{{ $t('SELL') }}</v-btn>
				</div>
			</div>
			<div class="card-preview_box" v-show="!is_mobile">
				<div class="card-preview">
					<img class="card-preview_bg" src="@/assets/bg_revoke.svg" alt="">
					<img class="card-preview_img" :src="card_img" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { SessionStorage } from 'storage-manager-js'
import { getAMTAmt, sell } from '@/utils/cardFactory'
import { mapState } from 'vuex'
import { getCardImgUrl } from '@/utils/tools'
import eventBus from '@/utils/eventBus'
import MyNftListNav from '@/views/Game/MyNFT/MyNftListNav'

export default {
	name: 'Sell',
	components: { MyNftListNav },
	data () {
		return {
			card: null,
			price: '',
			amt_balance: 0,
			sell_loading: false,
			nav_list: '',
			nav_index: 0,
			pc_btn_styles: {
				height: '52px !important',
				width: '263px',
				marginTop: '60px',
			},
			mobile_btn_styles: {
				height: '34px !important',
				width: '188px',
				marginTop: '44px',
			},
		}
	},
	computed: {
		...mapState(['ADDRESS']),
		card_img () {
			return getCardImgUrl(this.card.key)
		},
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
	},
	created () {
		const index = this.$route.query.index
		if (index) {
			this.nav_index = Number(index)
		}
		this.nav_list = SessionStorage.get('NFT_NAV_LIST')
		this.card = SessionStorage.get('TRANSACTION_CARD')
		console.log(this.card)
		if (this.ADDRESS) {
			this._amtBalance()
		}
		eventBus.$on('connect-event', async (res) => {
			this._amtBalance()
		})
	},
	methods: {
		navTap ({ index }) {
			this.$router.push({
				path: '/app/Game/MyNFT',
				query: { index },
			})
		},
		async sellCard () {
			if (this.price === '') return
			this.sell_loading = true
			const tokenId = this.card.ids[0]
			try {
				await sell(this.ADDRESS, tokenId, this.price)
				this.sell_loading = false
				this.$router.push('/app/NFTMarket')
			} catch (err) {
				this.sell_loading = false
			}
		},
		async _amtBalance () {
			this.amt_balance = await getAMTAmt(this.ADDRESS)
		},
	},
}
</script>

<style lang="scss" scoped>

</style>
